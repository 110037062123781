import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/Molecules';

const UserSubscriptionsList = ({ handleDeleteModalOpen, handleEditModalOpen, handleViewModalOpen, subscriptions }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const columns = [
    { field: 'name', text: t('fields.name') },
    { field: 'createdAt', text: t('fields.createdAt') }
  ];

  const rows = subscriptions?.map(subscription => ({
    createdAt: format(new Date(subscription.createdAt), 'dd/MM/yyyy - HH:mm'),
    id: subscription.userSubscriptionId,
    name: subscription.name
  }));

  return (
    <Table
      columns={columns}
      loading={!subscriptions}
      onDelete={handleDeleteModalOpen}
      onEdit={handleEditModalOpen}
      onView={handleViewModalOpen}
      rows={rows}
    />
  );
};

export default UserSubscriptionsList;
