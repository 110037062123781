import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

/**
 * A reusable empty state component that displays an icon and message when no data is available
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.icon - The icon to display
 * @param {string} props.title - The title text
 * @param {string} props.description - The description text
 * @param {React.ReactNode} props.action - Optional action button or component
 */
const EmptyState = ({ icon, title, description, action }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        px: 2,
        py: 6,
        textAlign: 'center',
        width: '100%'
      }}
    >
      {icon && (
        <Box sx={{ mb: 3 }}>
          {React.cloneElement(icon, {
            sx: { color: 'primary.light', fontSize: 80, opacity: 0.6, ...icon.props.sx }
          })}
        </Box>
      )}
      {title && (
        <Typography sx={{ color: 'text.primary', mb: 1 }} variant="h6">
          {title}
        </Typography>
      )}
      {description && (
        <Typography sx={{ color: 'text.secondary', maxWidth: '500px', mb: action ? 2 : 0, mx: 'auto' }} variant="body1">
          {description}
        </Typography>
      )}
      {action && <Box sx={{ mt: 2 }}>{action}</Box>}
    </Paper>
  );
};

export default EmptyState;
