import React from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const UserAntipassbackEdit = ({ action, data, handleClose }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const { details, userId } = data || {};
  const apbEnabled = details?.[0]?.apb || false;
  const hardAlert = details?.[0]?.hardAlert || false;

  const validationSchema = Yup.object().shape({
    apb: Yup.boolean(),
    hardAlert: Yup.boolean()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      apb: apbEnabled,
      hardAlert: hardAlert
    },
    validateOnChange: true,
    validationSchema: validationSchema
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleModalSubmit = async () => {
    await action({ ...formik.values, userId });
    !data && handleModalClose();
  };

  return (
    <form>
      <Stack gap={2}>
        <Typography color="textSecondary" variant="body2">
          {t('antipassback.description')}
        </Typography>

        <FormControlLabel
          control={<Checkbox checked={formik.values.apb} color="primary" name="apb" onBlur={formik.handleBlur} onChange={formik.handleChange} />}
          label={t('antipassback.enable')}
        />

        {formik.values.apb && (
          <Box sx={{ mt: 1 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="alertType-label">{t('antipassback.type')}</InputLabel>
              <Select
                id="hardAlert"
                label={t('antipassback.type')}
                labelId="alertType-label"
                name="hardAlert"
                onBlur={formik.handleBlur}
                onChange={e => {
                  formik.setFieldValue('hardAlert', e.target.value === 'hard');
                }}
                value={formik.values.hardAlert ? 'hard' : 'soft'}
              >
                <MenuItem value="soft">Soft</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <Button color="primary" disabled={!formik.isValid} onClick={handleModalSubmit} sx={{ mt: 2 }} variant="contained">
          {t('save')}
        </Button>
      </Stack>
    </form>
  );
};

export default UserAntipassbackEdit;
