import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ScheduleList = ({ schedule, loading }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users.schedules' });

  if (loading) {
    return <Typography>{t('loading')}</Typography>;
  }

  if (!schedule) {
    return <Typography>{t('noSchedule')}</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6">{t('assignedSchedule')}</Typography>
      <Typography sx={{ marginTop: 2 }} variant="body1">
        <strong>{t('fields.name')}:</strong> {schedule.name}
      </Typography>
      <Typography sx={{ marginTop: 2 }} variant="body1">
        <strong>{t('fields.timezone')}:</strong> {schedule.timezone}
      </Typography>
      <Typography sx={{ marginTop: 2 }} variant="body1">
        <strong>{t('fields.details')}:</strong>
      </Typography>
      <Box sx={{ marginTop: 1 }}>
        {schedule?.ScheduleDetails?.map(detail => (
          <Typography key={detail.scheduleDetailId} variant="body2">
            <strong>{t(`days.${detail.dayOfWeek.toLowerCase()}`)}</strong>: {detail.startTime} - {detail.endTime}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default ScheduleList;
