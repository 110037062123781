import { Alert, Button, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const GeneralInformationEdit = ({ action, data, handleClose, loading, parentUser }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const { email = '', firstName = '', lastName = '', userId } = data || {};

  // Improved email validation regex that prevents consecutive dots and other edge cases
  const emailRegex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('errors.required')).matches(emailRegex, t('errors.invalidEmail')),
    firstName: Yup.string()
      .trim()
      .required(t('errors.required'))
      .max(64, t('errors.maxLength', { max: 64 })),
    lastName: Yup.string()
      .trim()
      .required(t('errors.required'))
      .max(64, t('errors.maxLength', { max: 64 }))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email, firstName, lastName },
    validateOnChange: true,
    validationSchema: validationSchema
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleModalSubmit = async () => {
    await action({ ...formik.values, parentUserId: parentUser?.userId, userId });
    !data && !parentUser && handleModalClose(); // Only close modal when creating a new user
  };

  const isParentUserInactive = !data && parentUser?.details?.[0]?.status === false;
  const enabled = Object.keys(formik.touched).length && formik.isValid && !loading;

  if (isParentUserInactive) {
    return <Alert severity="warning">{t('warnings.inactiveParentUser')}</Alert>;
  }

  return (
    <form>
      <Stack gap={2}>
        {parentUser && (
          <Typography color="textSecondary" variant="body2">
            {t('captions.subUser')}
          </Typography>
        )}
        <Stack direction="row" gap={2}>
          <TextField
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            fullWidth
            helperText={formik.touched.firstName && formik.errors.firstName}
            inputProps={{ maxLength: 64 }}
            label={t('fields.firstName')}
            name="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.firstName}
          />
          <TextField
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            fullWidth
            helperText={formik.touched.lastName && formik.errors.lastName}
            inputProps={{ maxLength: 64 }}
            label={t('fields.lastName')}
            name="lastName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.lastName}
          />
        </Stack>
        <TextField
          disabled={!!data?.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          inputProps={{ maxLength: 100 }}
          label={t('fields.email')}
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
          value={formik.values.email}
        />
        <Button color="primary" disabled={!enabled} onClick={handleModalSubmit} sx={{ mt: 2 }} variant="contained">
          {t(data ? 'save' : 'invite')}
        </Button>
      </Stack>
    </form>
  );
};

export default GeneralInformationEdit;
