import { useContext, useEffect, useMemo } from 'react';
import { DeleteOutline } from '@mui/icons-material';
import { Button, CircularProgress, FormControl, IconButton, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import * as Yup from 'yup';

export function DateFieldComponent({ disabled, id, formik, name, onChange, value }) {
  const handleChange = value => {
    if (onChange) {
      onChange();
    } else if (formik) {
      formik.setFieldValue(name, new Date(value));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth>
        <DatePicker
          disabled={disabled}
          format="dd/MM/yyyy"
          id={id}
          name={name}
          onChange={handleChange}
          renderInput={params => <TextField {...params} />}
          value={value}
        />
      </FormControl>
    </LocalizationProvider>
  );
}

const UserSubscriptionEdit = ({ handleModalClose, handleSubscriptionSubmit, labels, loading, open, paymentsCatalog, subscription, userId }) => {
  const { customerId } = useContext(AuthorizationContext);
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const today = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  // Function to get first day of next month
  const getFirstDayOfNextMonth = () => {
    const date = new Date();
    // Set to first day of next month
    date.setMonth(date.getMonth() + 1, 1);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const initialValues = useMemo(
    () => ({
      associatedPayments:
        (subscription?.associatedPayments || []).map(ap => ({ paymentCatalogId: ap.paymentCatalogId, quantity: ap.quantity || 1 })) || [],
      firstPaymentDate: subscription?.firstPaymentDate ? new Date(subscription?.firstPaymentDate) : today,
      name: subscription?.name || '',
      nextPaymentDate: subscription?.nextPaymentDate ? new Date(subscription?.nextPaymentDate) : today,
      recurringChargePeriod: subscription?.recurringChargePeriod || 'month',
      status: typeof subscription?.status === 'boolean' ? subscription.status : false
    }),
    [subscription, today]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnChange: true,
    validationSchema: Yup.object({
      associatedPayments: Yup.array()
        .of(
          Yup.object().shape({
            paymentCatalogId: Yup.string().required(t('errors.required')),
            quantity: Yup.number()
              .min(1, t('errors.min', { min: 1 }))
              .required(t('errors.required'))
          })
        )
        .min(1, t('errors.required')),
      firstPaymentDate: subscription
        ? Yup.date().required(t('errors.required'))
        : Yup.date().min(today, t('errors.futureDate')).required(t('errors.required')),
      name: Yup.string().required(t('errors.required')),
      // nextPaymentDate: subscription
      //   ? Yup.date().required(t('errors.required'))
      //   : Yup.date()
      //       .oneOf([Yup.ref('firstPaymentDate')], t('errors.sameDate'))
      //       .required(t('errors.required')),
      recurringChargePeriod: Yup.string().required(t('errors.required')),
      status: Yup.boolean().required(t('errors.required'))
    })
  });

  const handleAssociatedPaymentsChange = event => {
    const selected = event.target.value;
    const uniqueSelected = selected.filter((value, index, self) => self.findIndex(ap => ap.paymentCatalogId === value.paymentCatalogId) === index);
    formik.setFieldValue('associatedPayments', uniqueSelected);
  };

  // Handle recurring charge period change
  const handleRecurringChargePeriodChange = event => {
    const value = event.target.value;
    formik.handleChange(event);

    // If period is month, set firstPaymentDate to first day of next month
    if (value === 'month' && !subscription) {
      formik.setFieldValue('firstPaymentDate', getFirstDayOfNextMonth());
    }
  };

  // Effect to update firstPaymentDate when recurringChargePeriod changes
  useEffect(() => {
    if (formik.values.recurringChargePeriod === 'month' && !subscription) {
      formik.setFieldValue('firstPaymentDate', getFirstDayOfNextMonth());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.recurringChargePeriod, subscription]);

  const handleSubmit = async () => {
    const associatedPayments = formik.values.associatedPayments.map(ap => ({ paymentCatalogId: ap.paymentCatalogId, quantity: ap.quantity }));
    const subscriptionPayload = {
      ...subscription,
      ...formik.values,
      associatedPayments,
      customerId,
      userId
    };
    if (!subscription) {
      subscriptionPayload.nextPaymentDate = subscriptionPayload.firstPaymentDate;
    }
    await handleSubscriptionSubmit(subscriptionPayload);
    formik.resetForm();
    handleModalClose();
  };

  const handleQuantityChange = (id, value) => {
    const newAssociatedPayments = formik.values.associatedPayments.map(ap => {
      if (ap.paymentCatalogId === id) {
        return { ...ap, quantity: value };
      }
      return ap;
    });
    formik.setFieldValue('associatedPayments', newAssociatedPayments);
  };

  const handleRemovePayment = id => {
    const newAssociatedPayments = formik.values.associatedPayments.filter(ap => ap.paymentCatalogId !== id);
    formik.setFieldValue('associatedPayments', newAssociatedPayments);
  };

  const enabled = (subscription || Object.keys(formik.touched).length) && formik.isValid && !loading;

  return (
    <Modal handleClose={handleModalClose} labels={labels} minHeight={400} minWidth={650} open={open}>
      <form>
        <Stack>
          <div>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
              {t('fields.name')}
            </Typography>
            <TextField
              error={formik.touched.name && Boolean(formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              id="name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </div>
          <div>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
              {t('fields.recurringChargePeriod')}
            </Typography>
            <Select
              error={formik.touched.recurringChargePeriod && Boolean(formik.errors.recurringChargePeriod)}
              fullWidth
              id="recurringChargePeriod"
              labelId="recurringChargePeriod-label"
              name="recurringChargePeriod"
              onBlur={formik.handleBlur}
              onChange={handleRecurringChargePeriodChange}
              value={formik.values.recurringChargePeriod}
            >
              <MenuItem value="month">{t('fields.month')}</MenuItem>
              <MenuItem value="year">{t('fields.year')}</MenuItem>
            </Select>
            {formik.touched.recurringChargePeriod && formik.errors.recurringChargePeriod && (
              <Typography color="error" variant="body2">
                {formik.errors.recurringChargePeriod}
              </Typography>
            )}
          </div>
          <div>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
              {t('fields.associatedPayments')}
            </Typography>
            <Select
              error={formik.touched.associatedPayments && Boolean(formik.errors.associatedPayments)}
              fullWidth
              id="associatedPayments"
              multiple
              name="associatedPayments"
              onBlur={formik.handleBlur}
              onChange={handleAssociatedPaymentsChange}
              renderValue={selected => selected.map(ap => paymentsCatalog.find(p => p.paymentCatalogId === ap.paymentCatalogId)?.name).join(', ')}
              value={formik.values.associatedPayments}
            >
              {(paymentsCatalog || [])
                .filter(p => p.recurringChargePeriod === formik.values.recurringChargePeriod)
                .map(p => (
                  <MenuItem key={p.paymentCatalogId} value={{ paymentCatalogId: p.paymentCatalogId, quantity: 1 }}>
                    {p.name}
                  </MenuItem>
                ))}
            </Select>
            {formik.values.associatedPayments.map(({ paymentCatalogId, quantity }) => (
              <Stack alignItems="center" direction="row" key={paymentCatalogId} spacing={2} sx={{ mt: 1 }}>
                <TextField
                  inputProps={{ min: 1 }}
                  label={`${t('fields.quantity')} ${paymentsCatalog.find(p => p.paymentCatalogId === paymentCatalogId)?.name}`}
                  onChange={e => handleQuantityChange(paymentCatalogId, e.target.value)}
                  type="number"
                  value={quantity}
                />
                <IconButton onClick={() => handleRemovePayment(paymentCatalogId)}>
                  <DeleteOutline />
                </IconButton>
              </Stack>
            ))}
          </div>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <div>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {t('fields.firstPaymentDate')}
              </Typography>
              <DateFieldComponent
                disabled={!!subscription || formik.values.recurringChargePeriod === 'month'}
                formik={formik}
                id="firstPaymentDate"
                name="firstPaymentDate"
                value={formik.values.firstPaymentDate}
              />
              {formik.touched.firstPaymentDate && formik.errors.firstPaymentDate && (
                <Typography color="error" variant="body2">
                  {formik.errors.firstPaymentDate}
                </Typography>
              )}
            </div>
            {/* <div>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {t('fields.nextPaymentDate')}
              </Typography>
              <DateFieldComponent
                disabled={!!subscription}
                formik={formik}
                id="nextPaymentDate"
                name="nextPaymentDate"
                value={formik.values.nextPaymentDate}
              />
              {formik.touched.nextPaymentDate && formik.errors.nextPaymentDate && (
                <Typography color="error" variant="body2">
                  {formik.errors.nextPaymentDate}
                </Typography>
              )}
            </div> */}
          </Stack>
          <div>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
              {t('fields.status')}
            </Typography>
            <Select
              error={formik.touched.status && Boolean(formik.errors.status)}
              fullWidth
              id="status"
              labelId="status-label"
              name="status"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              <MenuItem value={true}>{t('status.active')}</MenuItem>
              <MenuItem value={false}>{t('status.inactive')}</MenuItem>
            </Select>
            {formik.touched.status && formik.errors.status && (
              <Typography color="error" variant="body2">
                {formik.errors.status}
              </Typography>
            )}
          </div>
          <Button color="primary" disabled={!enabled} onClick={handleSubmit} sx={{ mt: 2 }} variant="contained">
            {loading ? <CircularProgress size={20} /> : t('save')}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default UserSubscriptionEdit;
