import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';

const UserSubscriptionView = ({ handleModalClose, labels, open, paymentsCatalog, subscription }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const getAssociatedPayment = ap => {
    const payment = paymentsCatalog.find(p => ap.paymentCatalogId === p.paymentCatalogId);
    return (
      <li key={ap.paymentCatalogId}>
        <Typography variant="body1">
          {ap.quantity}x {payment?.name}
          {payment?.amount && ` - ${new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(payment.amount)}`}
        </Typography>
      </li>
    );
  };
  return (
    <Modal handleClose={handleModalClose} labels={labels} minHeight={400} minWidth={650} open={open}>
      <Stack direction="row" gap={5}>
        <List>
          <ListItem>
            <ListItemText
              primary={<Typography style={{ fontWeight: 'bold' }}>{t('fields.recurringChargePeriod')}</Typography>}
              secondary={
                <Typography variant="body1">
                  {t(`period.${subscription?.recurringChargePeriod}`, { unit: subscription?.recurringChargeUnit })}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography style={{ fontWeight: 'bold' }}>{t('fields.firstPaymentDate')}</Typography>}
              secondary={
                <Typography variant="body1">
                  {subscription?.firstPaymentDate && format(new Date(subscription.firstPaymentDate), 'dd/MM/yyyy - HH:mm')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography style={{ fontWeight: 'bold' }}>{t('fields.nextPaymentDate')}</Typography>}
              secondary={
                <Typography variant="body1">
                  {subscription?.nextPaymentDate && format(new Date(subscription.nextPaymentDate), 'dd/MM/yyyy - HH:mm')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={<Typography style={{ fontWeight: 'bold' }}>{t('fields.status')}</Typography>}
              secondary={<Typography variant="body1">{t(`status.${subscription?.status ? '' : 'in'}active`)}</Typography>}
            />
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText primary={<Typography style={{ fontWeight: 'bold' }}>Pagos asociados</Typography>} />
          </ListItem>
          <ul>{subscription?.associatedPayments?.map(ap => getAssociatedPayment(ap))}</ul>
        </List>
      </Stack>
    </Modal>
  );
};

export default UserSubscriptionView;
