import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserAntipassbackView = ({ data }) => {
  const { details } = data || {};
  const apbEnabled = details?.[0]?.apb;
  const hardAlert = details?.[0]?.hardAlert;
  const alertType = hardAlert ? 'hard' : 'soft';

  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  return (
    <Box sx={{ p: 2 }}>
      <FormControlLabel
        control={<Checkbox checked={apbEnabled} color="primary" disabled name="enableAntipassback" readOnly />}
        label={t('antipassback.enable')}
      />

      {apbEnabled && (
        <Box sx={{ mt: 3 }}>
          <FormControl sx={{ minWidth: 200 }} variant="outlined">
            <InputLabel id="alertType-label">{t('antipassback.type')}</InputLabel>
            <Select disabled id="alertType" label={t('antipassback.type')} labelId="alertType-label" readOnly value={alertType}>
              <MenuItem value="soft">Soft</MenuItem>
              <MenuItem value="hard">Hard</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default UserAntipassbackView;
