import React, { useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomerForm from 'src/components/ClientsCRUD';
import QuoteEdit from 'src/components/Quote/QuoteEdit';
import QuoteView from 'src/components/Quote/QuoteView';

const Commerce = ({ isProspect }) => {
  const { t } = useTranslation(['registry', 'config']);
  const [clients, setClients] = useState();
  const [filteredClients, setFilteredClients] = useState([]);
  const [showAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [isQuoteEditModalOpen, setIsQuoteEditModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isQuoteViewModalOpen, setIsQuoteViewModalOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [dataBridges, setDataBridges] = useState([]); // Valor inicial vacío
  const [selectedBridges, setSelectedBridges] = useState([]);
  const [license, setLicense] = useState(undefined);
  const { accounts } = useMsal();

  const openQuoteEditModal = clientId => {
    setSelectedCustomerId(clientId);
    setIsQuoteEditModalOpen(true);
  };

  const theme = useTheme(); // Usar el tema actual para aplicar estilos dinámicamente

  const fetchClientDetails = async clientId => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/customer/${clientId}`);
      console.log('Detalles del cliente:', response.data);
      setLicense(response.data.licenses);
      setSelectedClientDetails(response.data);
    } catch (error) {
      console.error('Error fetching client details:', error);
      setSelectedClientDetails(null);
    }
  };

  const fetchSomeQuote = async clientId => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/quote/last/${clientId}`);
      return response; // Devuelve la respuesta para su uso en `handleViewDetails`
    } catch (error) {
      console.error('Error fetching some quote:', error);
      return null; // Manejo de errores básico
    }
  };

  const handleViewQuote = async clientId => {
    try {
      const response = await fetchSomeQuote(clientId);
      if (response?.data?.length > 0) {
        const quote = response.data[0];

        // Formatea los datos de la cotización
        const formattedQuote = {
          bridge: quote.number_of_bridges,
          businessPartner: {
            email: quote.businessPartnerEmail,
            name: quote.businessPartnerName
          },
          id: quote.id,
          numberOfAccessPoints: quote.number_of_access_points,
          numberOfUsers: quote.number_of_users,
          planName: quote.plan_id,
          pricePerUser: quote.price_per_user,
          propertyName: quote.nameQuoteCustomer,
          status: quote.status,
          totalPrice: quote.total_price
        };

        setSelectedQuote(formattedQuote);
        setIsQuoteViewModalOpen(true);
      } else {
        console.warn('No hay cotización disponible para este cliente.');
      }
    } catch (error) {
      console.error('Error al obtener la cotización:', error);
    }
  };

  console.log(license);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/customers`);
        console.log(response);

        const clients = response.data?.filter(client => {
          if (isProspect) {
            return client.stage === 'prospect';
          }
          return client.stage === 'customer';
        });
        setClients(clients);
        setFilteredClients(clients);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    if (!clients) fetchClients();
  }, [isProspect, clients]);

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value) {
      const filtered = clients.filter(
        client =>
          client.name.toLowerCase().includes(value) ||
          client.bc_email.toLowerCase().includes(value) ||
          `${client.bc_first_name} ${client.bc_last_name}`.toLowerCase().includes(value)
      );
      setFilteredClients(filtered);
    } else {
      setFilteredClients(clients);
    }
  };

  const handleViewDetails = async client => {
    try {
      // Primero verifica si existe una cotización para el cliente
      const response = await fetchSomeQuote(client.customer_id);
      if (response?.data?.length > 0) {
        console.log('Cotización encontrada:', response.data[0]);
        console.log(response.data[0].status === 'sent');
        if (response.data[0].status === 'sent') {
          setIsTrue(false);
        } else {
          setIsTrue(true);
        }
        await fetchClientDetails(client.customer_id);
        setIsDetailModalOpen(true);
      } else {
        setIsTrue(false);
        await fetchClientDetails(client.customer_id);
        setIsDetailModalOpen(true);
      }
    } catch (error) {
      console.error('Error al verificar cotizaciones:', error);
    }
  };

  //Funciones para manejar la asignación de Bridges a la licencia
  // Función para abrir el modal y cargar los datos
  const handleViewLicense = async clientId => {
    console.log('Cliente seleccionado:', clientId);
    try {
      const responseBridge = await axios.get(`${process.env.REACT_APP_API_URL}/sales/bridges`);
      console.log('Datos de bridges:', responseBridge.data);
      //Logica para filtrar los bridges que no estan asignados
      const bridges = responseBridge.data.filter(bridge => bridge.license_id === null);
      console.log('Bridges disponibles:', bridges);
      setDataBridges(bridges); // Datos de bridges
      setIsLicenseModalOpen(true); // Abre el modal
    } catch (error) {
      console.error('Error fetching license details:', error);
    }
  };

  // Función para manejar selección de Bridges
  const handleBridgeSelection = bridgeId => {
    setSelectedBridges(prev => (prev.includes(bridgeId) ? prev.filter(id => id !== bridgeId) : [...prev, bridgeId]));
  };

  // Función para enviar Bridges seleccionados
  const handleAssignBridges = async () => {
    console.log('Bridges seleccionados:', selectedBridges);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/sales/bridge/assign`, {
        bridge_ids: selectedBridges, // IDs de los bridges seleccionados
        license_id: license?.[0]?.license_id, // ID de la licencia
        updated_by: accounts?.[0]?.idTokenClaims?.sub
      });
      setIsLicenseModalOpen(false);
      //limpiar los bridges seleccionados
      setSelectedBridges([]);
      toast.success(t('commerce.modal.assignSuccess'));
    } catch (error) {
      console.error('Error asignando bridges:', error);
    }
  };

  const handleEditClient = async client => {
    await fetchClientDetails(client.customer_id);
    setIsFormModalOpen(true);
  };

  const handleNewClient = () => {
    setSelectedClientDetails(null);
    setIsFormModalOpen(true);
  };

  const formatDate = date => (date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : t('commerce.modal.noData'));

  const sendCUP = useCallback(
    async licenseId => {
      console.log('Enviando CUP:', licenseId);
      console.log('Enviado CUP', accounts?.[0]?.idTokenClaims?.sub);

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/sales/send-cup`, {
          licenseID: licenseId,
          sentBy: accounts?.[0]?.idTokenClaims?.sub
        });

        console.log('res:', res);
        if (res.status === 200) {
          toast.success('CUP enviado correctamente');
        }
      } catch (error) {
        console.error('Error al enviar CUP:', error);

        // Verifica si el error tiene un mensaje del servidor
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;

          // Mensaje específico
          if (errorMessage.includes('bridge relacionado')) {
            toast.error('No se puede enviar el CUP porque no hay ningún bridge asociado a la licencia.');
          } else {
            toast.error(`Error al enviar CUP: ${errorMessage}`);
          }
        } else {
          // Mensaje genérico para otros errores
          toast.error('Ocurrió un error inesperado al enviar el CUP.');
        }
      }
    },
    [accounts] // Dependencia correcta
  );

  return (
    <>
      <Box style={{ display: 'flex', gap: 20, justifyContent: 'end', marginBottom: 15 }}>
        <Button color="primary" onClick={handleNewClient} variant="contained">
          {t('commerce.addClient')}
        </Button>
        <TextField
          onChange={handleSearch}
          placeholder={t('commerce.searchPlaceholder')}
          style={{ maxWidth: 300 }}
          value={searchText}
          variant="outlined"
        />
      </Box>
      <TableContainer
        component={Paper}
        style={{
          backgroundColor: theme.palette.background.paper, // Adaptar al modo oscuro
          color: theme.palette.text.primary, // Texto adaptable
          margin: 'auto',
          marginTop: 20,
          maxWidth: 1200
        }}
      >
        <Table aria-label="Client Table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.clientName')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.businessEmail')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.businessContact')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.creationDate')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('commerce.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(showAll ? filteredClients : filteredClients.slice(0, 15)).map((client, index) => (
              <TableRow key={client.customer_id || index}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.bc_email}</TableCell>
                <TableCell>{`${client.bc_first_name} ${client.bc_last_name}`}</TableCell>
                <TableCell>{formatDate(client.created_at)}</TableCell>
                <TableCell>
                  <Box style={{ display: 'flex', gap: '10px' }}>
                    <Visibility onClick={() => handleViewDetails(client)} style={{ cursor: 'pointer' }} />
                    <Edit onClick={() => handleEditClient(client)} style={{ cursor: 'pointer' }} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!showAll && filteredClients.length > 15 && (
          <Button color="primary" style={{ display: 'block', margin: '20px auto' }} variant="contained">
            {t('commerce.showAll')}
          </Button>
        )}
      </TableContainer>

      <Modal onClose={() => setIsDetailModalOpen(false)} open={isDetailModalOpen}>
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 8,
            boxShadow: theme.shadows[5],
            color: theme.palette.text.primary,
            left: '50%',
            maxWidth: 800,
            padding: 20,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
          }}
        >
          {selectedClientDetails && (
            <>
              <Typography style={{ fontWeight: 'bold', marginBottom: 10 }} variant="h5">
                {selectedClientDetails.name}
              </Typography>
              <Typography gutterBottom variant="subtitle1">
                <strong>{t('commerce.modal.category')}:</strong> {selectedClientDetails.category || t('commerce.modal.noData')}
              </Typography>
              <Box style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}>
                <Box>
                  <Typography>
                    <strong>{t('commerce.modal.businessContactDetails')}:</strong>
                  </Typography>
                  <Typography>{`${selectedClientDetails.bc_first_name} ${selectedClientDetails.bc_last_name}`}</Typography>
                  <Typography>{selectedClientDetails.bc_email}</Typography>

                  <Typography>
                    <strong>{t('commerce.modal.operationalContact')}:</strong>
                  </Typography>
                  <Typography>{selectedClientDetails.oc_first_name || t('commerce.modal.noData')}</Typography>
                  <Typography>{selectedClientDetails.oc_email || t('commerce.modal.noData')}</Typography>

                  <Typography>
                    <strong>{t('commerce.modal.businessPartner')}:</strong>
                  </Typography>
                  <Typography>{t('commerce.modal.partnerName')}</Typography>
                  <Typography>{t('commerce.modal.partnerEmail')}</Typography>

                  <Typography>
                    <strong>{t('commerce.modal.creationDate')}:</strong> {formatDate(selectedClientDetails.created_at)}
                  </Typography>
                  <Typography>
                    <strong>{t('commerce.modal.modificationDate')}:</strong> {formatDate(selectedClientDetails.updated_at)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    <strong>{t('commerce.modal.address')}:</strong>
                  </Typography>
                  {selectedClientDetails.address ? (
                    <>
                      <Typography>{selectedClientDetails.address.address_line_1}</Typography>
                      <Typography>{selectedClientDetails.address.city}</Typography>
                      <Typography>{selectedClientDetails.address.postal_code}</Typography>
                    </>
                  ) : (
                    <Typography>{t('commerce.modal.noData')}</Typography>
                  )}
                  <Typography>
                    <strong>{t('commerce.modal.taxInfo')}:</strong>
                  </Typography>
                  {selectedClientDetails.tax_info ? (
                    <>
                      <Typography>{selectedClientDetails.tax_info.tax_name}</Typography>
                      <Typography>{selectedClientDetails.tax_info.rfc}</Typography>
                      <Typography>{selectedClientDetails.tax_info.postal_code}</Typography>
                    </>
                  ) : (
                    <Typography>{t('commerce.modal.noData')}</Typography>
                  )}
                  <Typography>{isProspect ? <strong>{t('Quote')}:</strong> : <strong>{t('license')}:</strong>}</Typography>
                  <Typography>{license ? license?.[0]?.license_id : t('commerce.modal.noData')}</Typography>
                  {isTrue ? (
                    isProspect ? (
                      <Link
                        onClick={() => handleViewQuote(selectedClientDetails.customer_id)}
                        style={{ cursor: 'pointer', display: 'block', marginTop: 20 }}
                      >
                        {t('commerce.buttons.link')}
                      </Link>
                    ) : (
                      <>
                        <Link
                          onClick={() => handleViewLicense(selectedClientDetails.customer_id)}
                          style={{ cursor: 'pointer', display: 'block', marginBottom: 20, marginTop: 20 }}
                        >
                          {t('commerce.buttons.assignBridge')}
                        </Link>

                        <Button
                          color="primary"
                          disabled={!license?.[0]?.license_id} // Deshabilita si no existe una licencia generada
                          onClick={() => sendCUP(license?.[0]?.license_id)} // Pasa el licenseID al hacer clic
                          sx={{ marginTop: '2px' }}
                          variant="contained"
                        >
                          {t('quote.quoteView.sendCUP', { ns: 'config' })}
                        </Button>
                      </>
                    )
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => openQuoteEditModal(selectedClientDetails.customer_id)} // Abre el modal y pasa el ID del cliente
                      variant="contained"
                    >
                      {t('commerce.buttons.newQuote')}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: 20 }}>
                <Button color="primary" onClick={() => setIsDetailModalOpen(false)} style={{ marginTop: 20 }} variant="outlined">
                  {t('commerce.modal.close')}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsDetailModalOpen(false);
                    setIsFormModalOpen(true);
                  }}
                  style={{ marginTop: 20 }}
                  variant="contained"
                >
                  {t('commerce.modal.edit')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      {/* Modal para Formulario prospecto */}
      <Modal onClose={() => setIsFormModalOpen(false)} open={isFormModalOpen}>
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 8,
            boxShadow: theme.shadows[5],
            color: theme.palette.text.primary,
            left: '50%',
            maxWidth: 900,
            padding: 20,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
          }}
        >
          <CustomerForm
            id={selectedClientDetails?.customer_id || null}
            initialValues={selectedClientDetails}
            isEditMode={!!selectedClientDetails}
            onClose={() => setIsFormModalOpen(false)}
          />
        </Box>
      </Modal>
      {/* Modal para crear una cotizaciones */}
      <Modal
        aria-describedby="quote-edit-modal-description"
        aria-labelledby="quote-edit-modal"
        onClose={() => setIsQuoteEditModalOpen(false)} // Cierra el modal al hacer clic fuera
        open={isQuoteEditModalOpen}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            left: '50%',
            maxWidth: '900px',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
          }}
        >
          <QuoteEdit
            onClose={() => setIsQuoteEditModalOpen(false)} // Cierra el modal directamente
            onSave={updatedQuote => {
              console.log('Cotización actualizada:', updatedQuote);
              setIsQuoteEditModalOpen(false); // Cierra el modal de edición
              setIsDetailModalOpen(false); // También cierra el modal de detalles
            }}
            quote={{ customer: selectedClientDetails, customer_id: selectedCustomerId }}
          />
        </Box>
      </Modal>
      {/* Modal para ver una Quote ya creada desde prospecto */}
      <Modal
        aria-describedby="quote-view-modal-description"
        aria-labelledby="quote-view-modal"
        onClose={() => setIsQuoteViewModalOpen(false)}
        open={isQuoteViewModalOpen}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            left: '50%',
            maxWidth: '900px',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%'
          }}
        >
          <QuoteView
            disableEdit={true} // Esto desactivará el botón de edición
            onEdit={updatedQuote => console.log('Editar cotización:', updatedQuote)}
            onSave={() => {
              console.log('Cotización guardada');
              setIsQuoteViewModalOpen(false);
            }}
            quote={selectedQuote}
          />
        </Box>
      </Modal>
      {/* Modal para asignar Bridges a la licencia */}
      <Modal onClose={() => setIsLicenseModalOpen(false)} open={isLicenseModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            left: '50%',
            maxHeight: '90vh',
            overflowY: 'scroll',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%'
          }}
        >
          <Typography sx={{ mb: 2 }} variant="h6">
            {t('commerce.modal.assignBridge')}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body2">
            {t('commerce.modal.assignBridgeText')}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t('commerce.modal.numberSerie')}</TableCell>
                  <TableCell>{t('commerce.modal.creationDate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataBridges && dataBridges.length > 0 ? (
                  dataBridges.map(bridge => (
                    <TableRow key={bridge.bridge_id}>
                      <TableCell>
                        <input
                          checked={selectedBridges.includes(bridge.bridge_id)}
                          onChange={() => handleBridgeSelection(bridge.bridge_id)}
                          type="checkbox"
                        />
                      </TableCell>
                      <TableCell>{bridge.serial_number}</TableCell>
                      <TableCell>{format(new Date(bridge.created_at), 'dd MMM yyyy HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      {t('commerce.modal.notBridgesData')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => setIsLicenseModalOpen(false)} sx={{ mr: 2 }}>
              {t('commerce.modal.close')}
            </Button>
            <Button color="primary" onClick={handleAssignBridges} variant="contained">
              {t('commerce.modal.assignBridge')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Commerce;
