import styled from '@emotion/styled';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { Box, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.divider}`,
  fontWeight: 'bold'
}));

const GenericTable = ({ columns, loading, onDelete, onEdit, onView, rows }) => {
  const { t } = useTranslation('global');

  const columnsCount = columns.length + (onDelete || onEdit || onView ? 1 : 0);
  const iconStyle = { fontSize: 20, p: 0 };
  const iconButtonStyle = { px: 1, py: 0.2 };

  const getRows = () => {
    if (loading) {
      return (
        <TableRow>
          {columns.map(column => (
            <TableCell key={column.field}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
          {(onDelete || onEdit || onView) && <TableCell />}
        </TableRow>
      );
    }
    if (!rows.length) {
      return (
        <TableRow>
          <TableCell colSpan={columnsCount}>{t('noDataAvailable')}</TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {rows.map(row => (
          <TableRow key={row.id}>
            {columns.map(column => (
              <TableCell key={column.field}>{row[column.field]}</TableCell>
            ))}
            {(onEdit || onView || onDelete) && (
              <TableCell>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {onView && (
                    <IconButton onClick={() => onView(row.id)} sx={iconButtonStyle}>
                      <Visibility sx={iconStyle} />
                    </IconButton>
                  )}
                  {onEdit && (
                    <IconButton onClick={() => onEdit(row.id)} sx={iconButtonStyle}>
                      <Edit sx={iconStyle} />
                    </IconButton>
                  )}
                  {onDelete && (
                    <IconButton onClick={() => onDelete(row.id)} sx={iconButtonStyle}>
                      <Delete sx={iconStyle} />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            )}
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <StyledTableCell key={column.field}>{column.text}</StyledTableCell>
            ))}
            {(onDelete || onEdit || onView) && <StyledTableCell />}
          </TableRow>
        </TableHead>
        <TableBody>{getRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};

GenericTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object)
};

export default GenericTable;
