import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useCRUD from 'src/hooks/useCRUD';

const ScheduleEdit = ({ schedules, customerId, user }) => {
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [initialScheduleId, setInitialScheduleId] = useState('');
  const { saveData } = useCRUD();
  const { t } = useTranslation('admin', { keyPrefix: 'property.users.schedules' });

  useEffect(() => {
    if (user?.scheduleId) {
      setSelectedSchedule(user.scheduleId);
      setInitialScheduleId(user.scheduleId);
    }
  }, [user]);

  const handleChange = e => {
    setSelectedSchedule(e.target.value);
  };

  const handleSave = async () => {
    if (selectedSchedule) {
      try {
        const response = await saveData(`${process.env.REACT_APP_API_URL}/property/${customerId}/user/${user.userId}`, 'PATCH', {
          scheduleId: selectedSchedule
        });

        if (response?.successInd) {
          toast.success(t('toast.success'));
        } else {
          toast.error(t('toast.error'));
        }
      } catch (error) {
        toast.error(t('toast.error'));
      }
    }
  };

  const isSaveDisabled = selectedSchedule === initialScheduleId;

  return (
    <Box sx={{ margin: 'auto', maxWidth: 400, padding: 3, width: '100%' }}>
      <Typography sx={{ marginBottom: 2 }} variant="h6">
        {t('title')}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="schedule-select-label">{t('select.label')}</InputLabel>
        <Select id="schedule-select" label={t('select.label')} labelId="schedule-select-label" onChange={handleChange} value={selectedSchedule}>
          <MenuItem value="">
            <em>{t('select.placeholder')}</em>
          </MenuItem>
          {schedules?.map(schedule => (
            <MenuItem key={schedule.scheduleId} value={schedule.scheduleId}>
              {schedule.name} ({schedule.timezone})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button color="primary" disabled={isSaveDisabled} fullWidth onClick={handleSave} variant="contained">
          {t('saveButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default ScheduleEdit;
