import { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import UserSubscriptionEditModal from './Edit';
import UserSubscriptionsList from './List';

const UserSubscriptionsCRUD = ({ user }) => {
  const { customerId } = useContext(AuthorizationContext);
  const {
    data: { userSubscriptions } = {},
    deleteData,
    loading,
    saveData
  } = useCRUD(user?.userId ? `${process.env.REACT_APP_API_URL}/user/${user?.userId}/subscriptions?customerId=${customerId}` : null);
  const { data: paymentsCatalog } = useCRUD(`${process.env.REACT_APP_API_URL}/properties/${customerId}/payments-catalog`);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedSubscription, setSelectedSubscription] = useState();

  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  useEffect(() => {
    if (userSubscriptions) {
      setFilteredSubscriptions(userSubscriptions);
    }
  }, [userSubscriptions]);

  const handleModalClose = setModalOpen => {
    setSelectedSubscription(null);
    setModalOpen(false);
  };

  const handleModalOpen = (userSubscriptionId, setModalOpen) => {
    if (userSubscriptionId) {
      setSelectedSubscription(userSubscriptions?.find(subscription => subscription.userSubscriptionId === userSubscriptionId));
    }
    setModalOpen(true);
  };

  const handleEditModalClose = () => handleModalClose(setEditModalOpen);
  const handleEditModalOpen = userSubscriptionId => handleModalOpen(userSubscriptionId, setEditModalOpen);

  const handleDelete = async userSubscriptionId => {
    const userConfirmed = window.confirm(t('messages.userSubscriptionDeleteConfirmationRequest'));
    if (userConfirmed) {
      try {
        await deleteData(`${process.env.REACT_APP_API_URL}/user/${user?.userId}/subscription/${userSubscriptionId}`);
      } catch (e) {
        toast.error(t(`messages.${user?.userId ? 'userUpdateError' : 'userCreateError'}`));
        console.error('Could not delete user subscription', e);
      }
    }
  };

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setQuery(value);

    setFilteredSubscriptions(value ? userSubscriptions?.filter(subscription => subscription.name.toLowerCase().includes(value)) : userSubscriptions);
  };

  const handleSubscriptionSubmit = async subscription => {
    const { userSubscriptionId } = subscription;
    const method = userSubscriptionId ? 'PATCH' : 'POST';
    const url = userSubscriptionId
      ? `${process.env.REACT_APP_API_URL}/user/${user?.userId}/subscription/${userSubscriptionId}`
      : `${process.env.REACT_APP_API_URL}/user/${user?.userId}/subscription`;

    try {
      const data = await saveData(url, method, { subscription: { ...subscription } });
      if (!data?.successInd) {
        toast.error(t(`messages.${user?.userId ? 'userUpdateError' : 'userAlreadyExists'}`));
        return;
      }
      toast.success(t(`messages.${user?.userId ? 'userUpdateSuccess' : 'userCreateSuccess'}`));
    } catch (error) {
      console.log(error);
      toast.error(t(`messages.${user?.userId ? 'userUpdateError' : 'userCreateError'}`));
    }
  };

  return (
    <>
      <Stack>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Button color="primary" onClick={handleEditModalOpen} variant="contained">
            {t('newSubscription')}
          </Button>
          <Stack direction="row">
            <TextField
              onChange={handleSearch}
              placeholder={t('placeholders.search')}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                },
                maxWidth: '300px'
              }}
              value={query}
              variant="outlined"
            />
          </Stack>
        </Box>
        <UserSubscriptionsList handleDeleteModalOpen={handleDelete} handleEditModalOpen={handleEditModalOpen} subscriptions={filteredSubscriptions} />
      </Stack>
      <UserSubscriptionEditModal
        handleModalClose={handleEditModalClose}
        handleSubscriptionSubmit={handleSubscriptionSubmit}
        labels={{ title: t(selectedSubscription ? 'editSubscription' : 'newSubscription') }}
        loading={loading}
        open={editModalOpen}
        paymentsCatalog={paymentsCatalog}
        subscription={selectedSubscription}
        userId={user?.userId}
      />
    </>
  );
};

export default UserSubscriptionsCRUD;
