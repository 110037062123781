import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import ScheduleList from './List';

const ScheduleIndex = ({ user }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users.schedules' });
  const { customerId } = useContext(AuthorizationContext);
  const { userId } = user || {};
  const { data: scheduleData, loading } = useCRUD(`${process.env.REACT_APP_API_URL}/property/${customerId}/user/${userId}`);
  const [schedule, setSchedule] = useState(null);

  const fetchScheduleDetails = useCallback(
    async scheduleId => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/${customerId}/schedules/${scheduleId}`);
        if (response?.data?.schedule) {
          setSchedule(response?.data?.schedule);
        } else {
          console.error(t('errors.fetchScheduleDetails'), response?.message);
        }
      } catch (error) {
        console.error(t('errors.fetchScheduleDetails'), error);
      }
    },
    [customerId, t]
  );

  useEffect(() => {
    if (scheduleData?.successInd && Array.isArray(user.details) && user.details.length > 0) {
      const scheduleId = user.details[0]?.scheduleId;

      if (scheduleId) {
        fetchScheduleDetails(scheduleId);
      }
    } else {
      console.error(t('errors.noScheduleId'));
    }
  }, [scheduleData, fetchScheduleDetails, user?.details, t]);

  return (
    <div>
      <ScheduleList loading={loading} schedule={schedule} />
    </div>
  );
};

export default ScheduleIndex;
