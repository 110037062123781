import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Esquema de validación Yup
const BridgeSchema = Yup.object().shape({
  bridge_key: Yup.string().trim().max(40, 'Máximo 40 caracteres'),
  license_id: Yup.string().trim().max(36, 'Máximo 36 caracteres'),
  name: Yup.string().trim().max(100, 'Máximo 100 caracteres'),
  serial_number: Yup.string().trim().required('El número de serie es obligatorio').max(40, 'Máximo 40 caracteres')
});

const CreateBridge = () => {
  const initialValues = {
    bridge_key: '',
    license_id: '',
    name: '',
    serial_number: ''
  };

  const { accounts } = useMsal();
  const { t } = useTranslation('admin', { keyPrefix: 'inventoryManagement.bridges' });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const API_URL = `${process.env.REACT_APP_API_URL}/sales/bridge`;

      // Enviar datos al API
      await axios.post(API_URL, {
        ...values,
        created_by: accounts?.[0]?.idTokenClaims?.sub
      });

      // Mostrar mensaje de éxito
      toast.success(t('success'));

      // Reiniciar el formulario
      resetForm();
    } catch (error) {
      console.error('Error al crear el bridge:', error);
      toast.error(t('error.generic'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        margin: 'auto',
        maxWidth: 500,
        padding: 3
      }}
    >
      <Typography gutterBottom variant="h5">
        {t('createBridge')}
      </Typography>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={BridgeSchema}>
        {({ errors, touched, isSubmitting, isValid, dirty }) => (
          <Form>
            {/* Serial Number */}
            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.serial_number && !!errors.serial_number}
                fullWidth
                helperText={touched.serial_number && errors.serial_number}
                label={t('serialNumber')}
                name="serial_number"
                variant="outlined"
              />
            </Box>

            {/* Bridge Key */}
            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.bridge_key && !!errors.bridge_key}
                fullWidth
                helperText={touched.bridge_key && errors.bridge_key}
                label={t('bridgeKey')}
                name="bridge_key"
                variant="outlined"
              />
            </Box>

            {/* License ID */}
            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.license_id && !!errors.license_id}
                fullWidth
                helperText={touched.license_id && errors.license_id}
                label={t('licenseId')}
                name="license_id"
                variant="outlined"
              />
            </Box>

            {/* Name */}
            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.name && !!errors.name}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('name')}
                name="name"
                variant="outlined"
              />
            </Box>

            {/* Submit Button */}
            <Button color="primary" disabled={isSubmitting || !(isValid && dirty)} fullWidth type="submit" variant="contained">
              {isSubmitting ? t('creating') : t('createBridge')}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateBridge;
