import React from 'react';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const UserStatusEdit = ({ action, data, handleClose, setUser }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const { details, userId } = data || {};
  const status = details?.[0]?.status;
  const parentUserId = details?.[0]?.parentUserId;

  const validationSchema = Yup.object().shape({
    status: Yup.boolean().required(t('errors.required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { status },
    validateOnChange: true,
    validationSchema: validationSchema
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleModalSubmit = async () => {
    await action({ ...formik.values, userId });
    // Workaround to update the status in the user object without fetching the user again
    setUser(user => ({ ...user, details: [{ ...user.details[0], status: formik.values.status }] }));
    !data && handleModalClose();
  };

  return (
    <form>
      <Stack gap={2}>
        <Typography color="textSecondary" variant="body2">
          {t('warnings.userStatus')}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox checked={!!formik.values?.status} color="primary" name="status" onBlur={formik.handleBlur} onChange={formik.handleChange} />
          }
          label={t('status.label')}
        />
        {formik.touched.userStatus && formik.errors.userStatus && <div style={{ color: 'red' }}>{formik.errors.userStatus}</div>}
        {parentUserId && (
          <Typography color="warning.main" variant="body2">
            {t('warnings.parentAccountControl')}
          </Typography>
        )}
        <Button color="primary" disabled={!formik.isValid} onClick={handleModalSubmit} variant="contained">
          {t('save')}
        </Button>
      </Stack>
    </form>
  );
};

export default UserStatusEdit;
