/* eslint-disable no-unused-vars */
/* eslint-disable sort-keys */
import React, { useState } from 'react';
import { Box, Button, FormHelperText, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import 'react-toastify/dist/ReactToastify.css';

const CustomerDetails = ({ data, saveData }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'configuration' });

  // Esquema de validación con Yup usando traducciones
  const validationSchema = Yup.object({
    commercialName: Yup.string().required(t('validation.commercialName')).max(50, t('validation.maxLength')),
    street: Yup.string().required(t('validation.street')),
    additionalInfo: Yup.string(),
    colony: Yup.string().required(t('validation.colony')),
    city: Yup.string().required(t('validation.city')),
    postalCode: Yup.string()
      .required(t('validation.postalCode'))
      .matches(/^\d{5}$/, t('validation.invalidPostalCode')),
    country: Yup.string().required(t('validation.country'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      commercialName: data?.name,
      street: data?.address?.address_line_1,
      additionalInfo: data?.address?.address_line_2,
      colony: data?.address?.neighborhood,
      city: data?.address?.city,
      postalCode: data?.address?.postal_code,
      country: data?.address?.country
    },
    validationSchema,
    onSubmit: async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/sales/customer/${data.customer_id}`;
        const method = 'PATCH';
        const { additionalInfo, city, colony, commercialName, postalCode, street } = formik.values;
        const response = await saveData(url, method, {
          address: { address_line_1: street, address_line_2: additionalInfo, city, neighborhood: colony, postal_code: postalCode },
          name: commercialName
        });
        console.log('Datos guardados:', response);
        toast.success(t('success'));
      } catch (error) {
        console.error('Error al guardar:', error);
        toast.error(t('error'));
      } finally {
        console.log('Datos guardados');
      }
    }
  });

  const [colonies, setColonies] = useState([]);
  const [city, setCity] = useState('');
  const [postalCodeSearched, setPostalCodeSearched] = useState(false);

  const fetchDataByPostalCode = async postalCode => {
    try {
      setPostalCodeSearched(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cp/json/${postalCode}`);
      const colonyList = response.data.map(item => item.d_asenta); // Extrae las colonias
      const cityName = response.data[0]?.d_ciudad || response.data[0]?.d_mnpio || ''; // Obtén la ciudad
      setColonies(colonyList);
      setCity(cityName);

      if (colonyList.length > 0) {
        // Solo cambia el valor si hay colonias disponibles
        formik.setFieldValue('colony', colonyList[0] || ''); // Selecciona la primera colonia
        formik.setFieldValue('city', cityName);
      } else {
        formik.setFieldValue('colony', '');
        formik.setFieldError('colony', t('validation.noColoniesFound'));
        formik.setFieldTouched('colony', true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setColonies([]);
      setCity('');
      setPostalCodeSearched(true);
      formik.setFieldValue('colony', ''); // Limpia el valor de la colonia
      formik.setFieldValue('city', ''); // Limpia el valor de la ciudad
      formik.setFieldError('colony', t('validation.coloniesError'));
      formik.setFieldTouched('colony', true);
    }
  };

  return (
    <>
      <ToastContainer
        autoClose={3000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '1.5rem',
              marginBottom: '2rem'
            }}
          >
            {/* Campo: Nombre comercial */}
            <Typography
              sx={{
                gridColumn: 'span 2',
                fontWeight: 'bold',
                marginBottom: '-0.5rem'
              }}
              variant="h6"
            >
              {t('commercialName')}
            </Typography>
            <TextField
              error={formik.touched.commercialName && Boolean(formik.errors.commercialName)}
              fullWidth
              helperText={formik.touched.commercialName && formik.errors.commercialName}
              InputLabelProps={{
                shrink: !!formik.values.commercialName // Fuerza el label hacia arriba si hay un valor
              }}
              label={t('name')}
              name="commercialName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              value={formik.values.commercialName}
              variant="outlined"
            />
            <Box></Box> {/* Espaciado para alinear títulos */}
            {/* Título: Dirección */}
            <Typography
              sx={{
                gridColumn: 'span 2',
                fontWeight: 'bold',
                marginBottom: '-0.5rem'
              }}
              variant="h6"
            >
              {t('address')}
            </Typography>
            {/* Campos de Dirección */}
            <TextField
              error={formik.touched.street && Boolean(formik.errors.street)}
              fullWidth
              helperText={formik.touched.street && formik.errors.street}
              InputLabelProps={{
                shrink: !!formik.values.commercialName // Fuerza el label hacia arriba si hay un valor
              }}
              label={t('street')}
              name="street"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              value={formik.values.street}
              variant="outlined"
            />
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: !!formik.values.commercialName // Fuerza el label hacia arriba si hay un valor
              }}
              label={t('additionalInfo')}
              name="additionalInfo"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              value={formik.values.additionalInfo}
              variant="outlined"
            />
            <TextField
              error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
              fullWidth
              helperText={formik.touched.postalCode && formik.errors.postalCode}
              InputLabelProps={{
                shrink: !!formik.values.commercialName // Fuerza el label hacia arriba si hay un valor
              }}
              label={t('postalCode')}
              name="postalCode"
              onBlur={formik.handleBlur}
              onChange={e => {
                formik.handleChange(e);
                const postalCode = e.target.value;
                if (/^\d{5}$/.test(postalCode)) {
                  fetchDataByPostalCode(postalCode); // Llama a la API si el código postal es válido
                } else {
                  setColonies([]);
                  setCity('');
                  formik.setFieldValue('city', ''); // Limpia el valor de la ciudad si el código postal no es válido
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              value={formik.values.postalCode}
              variant="outlined"
            />
            <Box>
              <Select
                error={(formik.touched.colony && Boolean(formik.errors.colony)) || (postalCodeSearched && colonies.length === 0)}
                fullWidth
                name="colony"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                  }
                }}
                value={formik.values.colony || ''} // Asegura que siempre haya un valor aunque sea vacío
              >
                {/* Opción predeterminada que muestra el valor inicial */}
                {formik.values.colony && (
                  <MenuItem disabled value={formik.values.colony}>
                    {formik.values.colony}
                  </MenuItem>
                )}

                {/* Colonias dinámicas desde el estado */}
                {colonies.length > 0 ? (
                  colonies.map((colony, index) => (
                    <MenuItem key={index} value={colony}>
                      {colony}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value="">
                    {postalCodeSearched ? t('validation.noColoniesFound') : t('validation.enterPostalCodeFirst')}
                  </MenuItem>
                )}
              </Select>
              {(formik.touched.colony && formik.errors.colony) || (postalCodeSearched && colonies.length === 0) ? (
                <FormHelperText error>{formik.errors.colony || t('validation.noColoniesFound')}</FormHelperText>
              ) : null}
            </Box>
            <TextField
              disabled
              error={formik.touched.city && Boolean(formik.errors.city)}
              fullWidth
              helperText={formik.touched.city && formik.errors.city}
              InputLabelProps={{
                shrink: !!formik.values.commercialName // Fuerza el label hacia arriba si hay un valor
              }}
              InputProps={{
                readOnly: true // Hace que el campo no sea editable
              }}
              label={t('city')}
              name="city"
              onBlur={formik.handleBlur}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              value={formik.values.city || city}
              variant="outlined"
            />
            <TextField
              disabled
              error={formik.touched.country && Boolean(formik.errors.country)}
              fullWidth
              helperText={formik.touched.country && formik.errors.country}
              InputLabelProps={{
                shrink: !!formik.values.commercialName // Fuerza el label hacia arriba si hay un valor
              }}
              InputProps={{
                readOnly: true // Hace que el campo no sea editable
              }}
              label={t('country')}
              name="country"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              value={formik.values.country}
              variant="outlined"
            />
          </Box>

          {/* Botón Guardar */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              disabled={!(formik.dirty && formik.isValid)} // Habilita solo si hay cambios y el formulario es válido
              sx={{
                backgroundColor: formik.dirty && formik.isValid ? '#282b4c' : '#c4c4c4',
                color: '#fff',
                padding: '0.8rem 2rem',
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: formik.dirty && formik.isValid ? '#1f2640' : '#c4c4c4'
                }
              }}
              type="submit"
              variant="contained"
            >
              {t('save')}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CustomerDetails;
