import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import ScheduleSettingsComponent from 'src/components/PropertyManager/Schedules/ScheduleSettings';

const ScheduleSettingsPage = () => {
  const { t } = useTranslation('admin', { keyPrefix: 'schedules' });

  return (
    <DefaultLayout title={t('title')}>
      <ScheduleSettingsComponent />
    </DefaultLayout>
  );
};
export default ScheduleSettingsPage;
