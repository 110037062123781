import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/Molecules';

const UsersList = ({ handleEditModalOpen, handleViewModalOpen, users }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  // Check if there are any EPU type users
  const hasEpuUsers = users.some(user => user.details?.[0]?.type === 'EPU');

  const columns = [
    { field: 'fullName', text: t('fields.fullName') },
    { field: 'email', text: t('fields.email') },
    { field: 'status', text: t('fields.status') },
    // Only add debtor column if we have EPU users
    ...(hasEpuUsers ? [{ field: 'debtor', text: t('fields.debtor') }] : [])
  ];

  const rows = users.map(user => {
    const isEpuUser = user.details?.[0]?.type === 'EPU';

    return {
      accountType: user.accountType,
      email: user.email,
      fullName: `${user.firstName} ${user.lastName}`,
      id: user.userId,
      status: t(user.details?.[0]?.status ? 'status.active' : 'status.inactive'),
      // Only include debtor field for EPU users, otherwise it will be undefined and not displayed
      ...(isEpuUser ? { debtor: t(user.details?.[0]?.debtor ? 'debtor.true' : 'debtor.false') } : {})
    };
  });

  return <Table columns={columns} loading={!users} onEdit={handleEditModalOpen} onView={handleViewModalOpen} rows={rows} />;
};

export default UsersList;
