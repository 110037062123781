import React from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/Molecules';

/**
 * Formatea una fecha a 'DD/MM/YYYY HH:mm' o devuelve '-' si no hay fecha.
 * @param {string} dateString - Fecha en formato ISO.
 * @returns {string} - Fecha formateada o '-'.
 */
const formatDate = dateString => {
  return dateString ? format(new Date(dateString), 'dd/MM/yyyy HH:mm', { locale: es }) : '-';
};

/**
 * Formatea los detalles del horario para mostrar los días de la semana y el horario.
 * @param {Array} details - Lista de detalles del horario.
 * @param {Function} t - Función de traducción.
 * @returns {string} - Cadena con los días y horario formateado.
 */
const formatScheduleDetails = (details, t) => {
  if (!details || details.length === 0) return t('messages.noDays');

  const weekOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  // Ordenamos los días según el orden de la semana
  const orderedDays = details.map(d => d.dayOfWeek).sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));

  // Agrupar los días consecutivos en rangos
  let groupedDays = '';
  let start = orderedDays[0];
  let prev = orderedDays[0];

  for (let i = 1; i < orderedDays.length; i++) {
    if (weekOrder.indexOf(orderedDays[i]) !== weekOrder.indexOf(prev) + 1) {
      groupedDays +=
        start === prev ? `${t(`days.${start.toLowerCase()}`)}, ` : `${t(`days.${start.toLowerCase()}`)}-${t(`days.${prev.toLowerCase()}`)}, `;
      start = orderedDays[i];
    }
    prev = orderedDays[i];
  }

  groupedDays += start === prev ? t(`days.${start.toLowerCase()}`) : `${t(`days.${start.toLowerCase()}`)}-${t(`days.${prev.toLowerCase()}`)}`;

  const hours = `${details[0].startTime} - ${details[0].endTime}`; // Usa el primer horario
  return `${groupedDays} ${hours}`;
};

const ScheduleList = ({ schedules, handleEditModalOpen, handleViewModalOpen }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'schedules' });

  const columns = [
    { field: 'name', text: t('columns.name') },
    { field: 'schedule', text: t('columns.schedule') },
    { field: 'createdAt', text: t('columns.createdAt') },
    { field: 'updatedAt', text: t('columns.updatedAt') }
  ];

  const rows =
    schedules?.map(schedule => ({
      createdAt: formatDate(schedule.createdAt),
      id: schedule.scheduleId,
      name: schedule.name,
      schedule: `${formatScheduleDetails(schedule.ScheduleDetails, t)} (${schedule.timezone})`,
      updatedAt: formatDate(schedule.updatedAt)
    })) || [];

  return (
    <Box>
      <Table columns={columns} onEdit={handleEditModalOpen} onView={handleViewModalOpen} rows={rows} />
    </Box>
  );
};

export default ScheduleList;
