import { useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import UserSubscriptionsList from '../../Edit/Subscriptions/List';
import UserSubscriptionViewModal from './List';

const UserSubscriptionsView = ({ user }) => {
  const { customerId } = useContext(AuthorizationContext);
  const { data: { userSubscriptions } = {} } = useCRUD(
    user?.userId ? `${process.env.REACT_APP_API_URL}/user/${user?.userId}/subscriptions?customerId=${customerId}` : null
  );
  const { data: paymentsCatalog } = useCRUD(`${process.env.REACT_APP_API_URL}/properties/${customerId}/payments-catalog`);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleModalClose = setModalOpen => {
    setSelectedSubscription(null);
    setModalOpen(false);
  };

  const handleModalOpen = (userSubscriptionId, setModalOpen) => {
    if (userSubscriptionId) setSelectedSubscription(userSubscriptions?.find(subscription => subscription.userSubscriptionId === userSubscriptionId));
    setModalOpen(true);
  };

  const handleViewModalClose = () => handleModalClose(setViewModalOpen);
  const handleViewModalOpen = userSubscriptionId => handleModalOpen(userSubscriptionId, setViewModalOpen);

  return (
    <>
      <Stack>
        <UserSubscriptionsList handleViewModalOpen={handleViewModalOpen} subscriptions={userSubscriptions} />
      </Stack>
      <UserSubscriptionViewModal
        handleModalClose={handleViewModalClose}
        labels={{ title: selectedSubscription?.name }}
        open={viewModalOpen}
        paymentsCatalog={paymentsCatalog}
        subscription={selectedSubscription}
      />
    </>
  );
};

export default UserSubscriptionsView;
