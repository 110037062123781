import React, { useContext, useEffect, useState } from 'react';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import ScheduleEdit from './Edit';

const ScheduleIndex = ({ user }) => {
  const { customerId } = useContext(AuthorizationContext);
  const { data: schedulesData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/${customerId}/schedules`);
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    if (schedulesData?.successInd && Array.isArray(schedulesData.schedules)) {
      setSchedules(schedulesData.schedules);
    }
  }, [schedulesData]);

  return (
    <div>
      <ScheduleEdit customerId={customerId} schedules={schedules} user={user} />
    </div>
  );
};

export default ScheduleIndex;
