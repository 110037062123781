import React, { useContext, useEffect, useState } from 'react';
import { Delete, Edit, ReceiptLong, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { EmptyState } from 'src/components/Molecules';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';
import * as Yup from 'yup';

const PaymentRulesTable = () => {
  const { customerId } = useContext(AuthorizationContext);
  const {
    data,
    deleteData,
    saveData,
    loading: crudLoading
  } = useCRUD(`${process.env.REACT_APP_API_URL}/property/payments/rules?customer_id=${customerId}`);
  const [filteredRules, setFilteredRules] = useState(data || []);
  const [selectedRule, setSelectedRule] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings.paymentRules' });

  const hasExistingRule = filteredRules && filteredRules.length > 0;

  useEffect(() => {
    if (data) {
      setFilteredRules(data);
    }
  }, [data]);

  // Update validation schema
  const validationSchema = Yup.object({
    delinquencyTimePeriod: Yup.string().required(t('alerts.delinquencyTimePeriod')).notOneOf([''], t('alerts.validOption')),
    delinquencyTimeUnit: Yup.number().required(t('alerts.delinquencyTimeUnit')).positive(t('alerts.positiveNumber')).integer(t('alerts.integer')),
    name: Yup.string().required(t('alerts.name')),
    penaltyChargeAmount: Yup.number().required(t('alerts.penaltyChargeAmount')).positive(t('alerts.positiveNumber')),
    penaltyChargeType: Yup.string().required(t('alerts.penaltyChargeType')).notOneOf([''], t('alerts.validOption')),
    penaltyTimePeriod: Yup.string().required(t('alerts.penaltyTimePeriod')).notOneOf([''], t('alerts.validOption')),
    penaltyTimeUnit: Yup.number().required(t('alerts.penaltyTimeUnit')).positive(t('alerts.positiveNumber')).integer(t('alerts.integer'))
  });

  const openEditModal = rule => {
    setSelectedRule(rule);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const openViewModal = rule => {
    setSelectedRule(rule);
    setIsViewModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRule(null);
    setIsEditMode(false);
    setIsModalOpen(false);
  };

  const closeViewModal = () => {
    setSelectedRule(null);
    setIsViewModalOpen(false);
  };

  const openCreateModal = () => {
    if (hasExistingRule) {
      toast.error(t('toasts.onlyOneRuleAllowed') || 'Only one payment rule is allowed');
      return;
    }
    setIsModalOpen(true);
  };

  const handleSave = async values => {
    if (submitting) return;

    setSubmitting(true);
    const url = `${process.env.REACT_APP_API_URL}/property/payments/rules/${selectedRule.paymentRuleId}`;
    try {
      await saveData(url, 'PATCH', { ...values, customerId: selectedRule.customerId });
      toast.success(t('toasts.updateSuccess'));
      closeModal();
    } catch (error) {
      toast.error(t('toasts.updateError'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleCreate = async values => {
    if (submitting) return;

    if (hasExistingRule) {
      toast.error(t('toasts.onlyOneRuleAllowed') || 'Only one payment rule is allowed');
      closeModal();
      return;
    }

    setSubmitting(true);
    const url = `${process.env.REACT_APP_API_URL}/property/payments/rules`;
    try {
      await saveData(url, 'POST', { ...values, customerId });
      toast.success(t('toasts.createSuccess'));
      closeModal();
    } catch (error) {
      toast.error(t('toasts.createError'));
    } finally {
      setSubmitting(false);
    }
  };

  const confirmDeleteRule = ruleId => {
    setRuleToDelete(ruleId);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (!ruleToDelete) return;

    setSubmitting(true);
    try {
      await deleteData(`${process.env.REACT_APP_API_URL}/property/payments/rules/${ruleToDelete}`, 'DELETE');
      toast.success(t('toasts.deleteSuccess'));
      setDeleteConfirmOpen(false);
      setRuleToDelete(null);
    } catch (error) {
      toast.error(t('toasts.deleteError'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <ToastContainer />
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Button color="primary" disabled={hasExistingRule} onClick={openCreateModal} variant="contained">
          {t('buttons.create')}
        </Button>
      </Box>

      {crudLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : filteredRules.length > 0 ? (
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.name')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.penaltyPeriod')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.penaltyAmount')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.delinquencyPeriod')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRules.map((rule, index) => (
                <TableRow key={rule.paymentRuleId || index}>
                  <TableCell>{rule.name}</TableCell>
                  <TableCell>
                    {rule.penaltyTimeUnit > 1
                      ? `${rule.penaltyTimeUnit} ${rule.penaltyTimePeriod === 'day' ? t('timePeriods.days') : rule.penaltyTimePeriod === 'month' ? t('timePeriods.months') : t('timePeriods.years')}`
                      : `${rule.penaltyTimeUnit} ${rule.penaltyTimePeriod === 'day' ? t('timePeriods.day') : rule.penaltyTimePeriod === 'month' ? t('timePeriods.month') : t('timePeriods.year')}`}
                  </TableCell>
                  <TableCell>{`${rule.penaltyChargeAmount} ${rule.penaltyChargeType === 'percentage' ? '%' : rule.penaltyChargeType === 'fixed' ? '$' : ''}`}</TableCell>
                  <TableCell>
                    {rule.delinquencyTimeUnit > 1
                      ? `${rule.delinquencyTimeUnit} ${rule.delinquencyTimePeriod === 'day' ? t('timePeriods.days') : rule.delinquencyTimePeriod === 'month' ? t('timePeriods.months') : t('timePeriods.years')}`
                      : `${rule.delinquencyTimeUnit} ${rule.delinquencyTimePeriod === 'day' ? t('timePeriods.day') : rule.delinquencyTimePeriod === 'month' ? t('timePeriods.month') : t('timePeriods.year')}`}
                  </TableCell>
                  <TableCell>
                    <Box style={{ display: 'flex' }}>
                      <IconButton onClick={() => openEditModal(rule)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => openViewModal(rule)}>
                        <Visibility />
                      </IconButton>
                      <IconButton onClick={() => confirmDeleteRule(rule.paymentRuleId)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState
          description={t('info.noRulesFound') || 'No payment rules found. Create one to get started.'}
          icon={<ReceiptLong />}
          title={t('info.noRulesTitle') || 'No Payment Rules Found'}
        />
      )}

      {hasExistingRule && <Typography sx={{ color: 'text.secondary', fontStyle: 'italic', mt: 2 }}>{t('notes.onlyOneRuleAllowed')}</Typography>}

      <Modal onClose={closeModal} open={isModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600
          }}
        >
          <Typography sx={{ marginBottom: 2 }} variant="h6">
            {isEditMode ? t('modal.editTitle') : t('modal.createTitle')}
          </Typography>
          <Formik
            enableReinitialize
            initialValues={
              isEditMode
                ? selectedRule || {
                    delinquencyTimePeriod: '',
                    delinquencyTimeUnit: '',
                    name: '',
                    penaltyChargeAmount: '',
                    penaltyChargeType: '',
                    penaltyTimePeriod: '',
                    penaltyTimeUnit: ''
                  }
                : {
                    delinquencyTimePeriod: '',
                    delinquencyTimeUnit: '',
                    name: '',
                    penaltyChargeAmount: '',
                    penaltyChargeType: '',
                    penaltyTimePeriod: '',
                    penaltyTimeUnit: ''
                  }
            }
            onSubmit={isEditMode ? handleSave : handleCreate}
            validationSchema={validationSchema}
          >
            {({ dirty, errors, touched, isValid, values, handleBlur, setFieldValue }) => (
              <Form>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Field
                    as={TextField}
                    error={touched.name && !!errors.name}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t('fields.name')}
                    name="name"
                    sx={{ marginBottom: 2 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    error={touched.penaltyTimeUnit && !!errors.penaltyTimeUnit}
                    fullWidth
                    helperText={touched.penaltyTimeUnit && errors.penaltyTimeUnit}
                    inputProps={{ min: 1, type: 'number' }}
                    label={t('fields.penaltyTimeUnit')}
                    name="penaltyTimeUnit"
                    onBlur={handleBlur}
                    onChange={e => {
                      const value = e.target.value ? parseInt(e.target.value) : '';
                      setFieldValue('penaltyTimeUnit', value);
                    }}
                    sx={{ marginBottom: 2 }}
                    value={values.penaltyTimeUnit}
                  />
                  <Field
                    as={TextField}
                    error={touched.penaltyTimePeriod && !!errors.penaltyTimePeriod}
                    fullWidth
                    helperText={touched.penaltyTimePeriod && errors.penaltyTimePeriod}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={t('fields.penaltyTimePeriod')}
                    name="penaltyTimePeriod"
                    select
                    SelectProps={{ native: true }}
                    sx={{ marginBottom: 2 }}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="day">{t('options.days')}</option>
                  </Field>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    error={touched.penaltyChargeAmount && !!errors.penaltyChargeAmount}
                    fullWidth
                    helperText={touched.penaltyChargeAmount && errors.penaltyChargeAmount}
                    inputProps={{ min: 0.01, step: 0.01, type: 'number' }}
                    label={t('fields.penaltyChargeAmount')}
                    name="penaltyChargeAmount"
                    onBlur={handleBlur}
                    onChange={e => {
                      const value = e.target.value ? parseFloat(e.target.value) : '';
                      setFieldValue('penaltyChargeAmount', value);
                    }}
                    sx={{ marginBottom: 2 }}
                    value={values.penaltyChargeAmount}
                  />
                  <Field
                    as={TextField}
                    error={touched.penaltyChargeType && !!errors.penaltyChargeType}
                    fullWidth
                    helperText={touched.penaltyChargeType && errors.penaltyChargeType}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={t('fields.penaltyChargeType')}
                    name="penaltyChargeType"
                    select
                    SelectProps={{ native: true }}
                    sx={{ marginBottom: 2 }}
                  >
                    <option value="">{t('options.selectOption')}</option>
                    <option value="percentage">%</option>
                    <option value="fixed">$</option>
                  </Field>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    error={touched.delinquencyTimeUnit && !!errors.delinquencyTimeUnit}
                    fullWidth
                    helperText={touched.delinquencyTimeUnit && errors.delinquencyTimeUnit}
                    inputProps={{ min: 1, type: 'number' }}
                    label={t('fields.delinquencyTimeUnit')}
                    name="delinquencyTimeUnit"
                    onBlur={handleBlur}
                    onChange={e => {
                      const value = e.target.value ? parseInt(e.target.value) : '';
                      setFieldValue('delinquencyTimeUnit', value);
                    }}
                    sx={{ marginBottom: 2 }}
                    value={values.delinquencyTimeUnit}
                  />
                  <Field
                    as={TextField}
                    error={touched.delinquencyTimePeriod && !!errors.delinquencyTimePeriod}
                    fullWidth
                    helperText={touched.delinquencyTimePeriod && errors.delinquencyTimePeriod}
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={t('fields.delinquencyTimePeriod')}
                    name="delinquencyTimePeriod"
                    select
                    SelectProps={{ native: true }}
                    sx={{ marginBottom: 2 }}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="month">{t('options.months')}</option>
                  </Field>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                  <Button onClick={closeModal} variant="outlined">
                    {t('buttons.cancel')}
                  </Button>
                  <Button color="primary" disabled={!isValid || (!dirty && isEditMode) || submitting} type="submit" variant="contained">
                    {submitting ? <CircularProgress size={24} sx={{ color: 'inherit' }} /> : isEditMode ? t('buttons.save') : t('buttons.create')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Modal onClose={closeViewModal} open={isViewModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500
          }}
        >
          <Typography sx={{ marginBottom: 2 }} variant="h5">
            {t('viewDetails')}
          </Typography>
          {selectedRule && (
            <Box>
              <Typography>
                <strong>{t('tableHeaders.name')}:</strong> {selectedRule.name}
              </Typography>
              <Typography>
                <strong>{t('tableHeaders.penaltyPeriod')}:</strong>{' '}
                {selectedRule.penaltyTimeUnit > 1
                  ? `${selectedRule.penaltyTimeUnit} ${
                      selectedRule.penaltyTimePeriod === 'day'
                        ? t('timePeriods.days')
                        : selectedRule.penaltyTimePeriod === 'month'
                          ? t('timePeriods.months')
                          : selectedRule.penaltyTimePeriod === 'year'
                            ? t('timePeriods.years')
                            : ''
                    }`
                  : `${selectedRule.penaltyTimeUnit} ${
                      selectedRule.penaltyTimePeriod === 'day'
                        ? t('timePeriods.day')
                        : selectedRule.penaltyTimePeriod === 'month'
                          ? t('timePeriods.month')
                          : selectedRule.penaltyTimePeriod === 'year'
                            ? t('timePeriods.year')
                            : ''
                    }`}
              </Typography>
              <Typography>
                <strong>{t('tableHeaders.penaltyAmount')}:</strong>{' '}
                {`${selectedRule.penaltyChargeAmount} ${
                  selectedRule.penaltyChargeType === 'percentage' ? '%' : selectedRule.penaltyChargeType === 'fixed' ? '$' : ''
                }`}
              </Typography>
              <Typography>
                <strong>{t('tableHeaders.delinquencyPeriod')}:</strong>{' '}
                {selectedRule.delinquencyTimeUnit > 1
                  ? `${selectedRule.delinquencyTimeUnit} ${
                      selectedRule.delinquencyTimePeriod === 'day'
                        ? t('timePeriods.days')
                        : selectedRule.delinquencyTimePeriod === 'month'
                          ? t('timePeriods.months')
                          : selectedRule.delinquencyTimePeriod === 'year'
                            ? t('timePeriods.years')
                            : ''
                    }`
                  : `${selectedRule.delinquencyTimeUnit} ${
                      selectedRule.delinquencyTimePeriod === 'day'
                        ? t('timePeriods.day')
                        : selectedRule.delinquencyTimePeriod === 'month'
                          ? t('timePeriods.month')
                          : selectedRule.delinquencyTimePeriod === 'year'
                            ? t('timePeriods.year')
                            : ''
                    }`}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={closeViewModal} variant="outlined">
              {t('buttons.close')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete confirmation dialog */}
      <Dialog onClose={() => setDeleteConfirmOpen(false)} open={deleteConfirmOpen}>
        <DialogTitle>{t('deleteConfirm.title') || 'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('deleteConfirm.message') || 'Are you sure you want to delete this payment rule? This action cannot be undone.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteConfirmOpen(false)}>
            {t('buttons.cancel')}
          </Button>
          <Button color="error" disabled={submitting} onClick={handleDelete} variant="contained">
            {submitting ? <CircularProgress size={24} sx={{ color: 'inherit' }} /> : t('deleteConfirm.confirmButton') || 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PaymentRulesTable;
