import React, { useContext, useState } from 'react';
import { Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';
import * as Yup from 'yup';

function ComponentePaymentsRules() {
  const { customerId } = useContext(AuthorizationContext);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedPago, setSelectedPago] = useState(null);
  const { data: pagos, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/properties/${customerId}/payments-catalog`);
  const { t } = useTranslation('admin');

  const initialValues = {
    amount: '',
    category: '',
    name: '',
    notes: '',
    recurring: false,
    recurringChargePeriod: '',
    variableCost: false
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError(t('payments.fieldNumber'))
      .positive(t('payments.fieldAmount'))
      .nullable()
      .when('variableCost', {
        is: true,
        otherwise: schema => schema.required(t('payments.fieldAmountRequired')),
        then: schema => schema.notRequired().nullable()
      }),
    name: Yup.string().required(t('payments.fieldNameRequired')),
    recurringChargePeriod: Yup.string().required(t('payments.fieldPeriodicityRequired'))
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          amount: values.amount ? parseFloat(values.amount) : null,
          category: values.category,
          customerId: customerId,
          name: values.name,
          notes: values.notes,
          recurring: values.recurring,
          recurringChargePeriod: values.recurringChargePeriod,
          ...(isEditing ? { updatedBy: customerId } : { createdBy: customerId })
        };
        if (isEditing) {
          await saveData(
            `${process.env.REACT_APP_API_URL}/properties/${customerId}/payments-catalog/${selectedPago.paymentCatalogId}`,
            'PATCH',
            payload
          );
          toast.success(t('payments.saveSuccess')); // Éxito al editar
          console.log('Pago actualizado');
        } else {
          await saveData(`${process.env.REACT_APP_API_URL}/properties/${customerId}/payments-catalog`, 'POST', payload);
          toast.success(t('payments.saveSuccess')); // Éxito al crear
          console.log('Pago creado');
        }

        resetForm();
        setOpenCreateModal(false);
      } catch (error) {
        toast.error(t('payments.saveError')); // Error al guardar/actualizar
        console.error('Error al guardar/actualizar el pago:', error);
      }
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema
  });

  const handleCloseCreateModal = () => {
    formik.resetForm();
    setOpenCreateModal(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setTimeout(() => setSelectedPago(null), 300);
  };

  const handleOpenCreateModal = () => {
    setIsEditing(false);
    setSelectedPago(null);
    formik.resetForm();
    setOpenCreateModal(true);
  };

  const handleOpenDialog = pago => {
    setSelectedPago(pago);
    setOpen(true);
  };

  const handleOpenEditModal = pago => {
    setIsEditing(true);
    setSelectedPago(pago);
    formik.setValues({
      amount: pago.amount || '', // Mantén el valor vacío si viene así
      category: pago.category || '',
      name: pago.name || '',
      notes: pago.notes || '',
      recurring: pago.recurring || false,
      recurringChargePeriod: pago.recurringChargePeriod || '',
      variableCost: pago.amount === '' || pago.amount === null // Activa el checkbox si amount está vacío o es null
    });
    setOpenCreateModal(true);
  };

  const handleVariableCostChange = e => {
    const isChecked = e.target.checked;
    formik.setFieldValue('variableCost', isChecked);

    if (isChecked) {
      // Limpiar valor y error del campo 'amount' manualmente
      formik.setFieldValue('amount', ''); // Limpia el valor
      formik.setFieldTouched('amount', false); // Marca como no tocado
      formik.setErrors(prevErrors => ({ ...prevErrors, amount: undefined })); // Elimina el error
    } else {
      // Validar manualmente el campo 'amount' si se desactiva el checkbox
      formik.setFieldTouched('amount', true);
      formik.validateField('amount');
    }
  };

  const filteredPagos = pagos?.filter(pago => pago.name?.toLowerCase().includes(search.toLowerCase())) || [];
  const isFormReady =
    formik.dirty && formik.values.name && formik.values.recurringChargePeriod && (formik.values.variableCost || formik.values.amount);

  console.log('Errors:', formik.errors);
  console.log('Values:', formik.values);
  console.log('Is Valid:', formik.isValid);
  console.log('Is Dirty:', formik.dirty);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button color="primary" onClick={handleOpenCreateModal} variant="contained">
          {t('payments.newPayment')}
        </Button>
        <Box alignItems="center" display="flex">
          <TextField
            label={t('payments.name')}
            onChange={e => setSearch(e.target.value)}
            size="small"
            style={{ marginRight: '8px' }}
            value={search}
            variant="outlined"
          />
          <Button variant="contained">{t('payments.search')}</Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('payments.name')}</TableCell>
              <TableCell>{t('payments.amount')}</TableCell>
              <TableCell>{t('payments.type')}</TableCell>
              <TableCell>{t('payments.periodicity')}</TableCell>
              <TableCell>{t('payments.dateCreation')}</TableCell>
              <TableCell>{t('payments.dateUpdate')}</TableCell>
              <TableCell>{t('payments.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPagos.map(pago => (
              <TableRow key={pago.paymentCatalogId}>
                <TableCell>{pago.name}</TableCell>
                <TableCell>{pago.amount ? pago.amount : '-'}</TableCell>
                <TableCell>{pago.recurring ? t('payments.recurring') : t('payments.unique')}</TableCell>
                <TableCell>
                  {pago.recurringChargePeriod === 'year'
                    ? t('payments.year')
                    : pago.recurringChargePeriod === 'month'
                      ? t('payments.month')
                      : pago.recurringChargePeriod}
                </TableCell>
                <TableCell>{new Date(pago.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{pago.updatedAt ? new Date(pago.updatedAt).toLocaleDateString() : '-'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(pago)}>
                    <Visibility />
                  </IconButton>
                  <IconButton onClick={() => handleOpenEditModal(pago)}>
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth maxWidth="sm" onClose={handleCloseDialog} open={open && selectedPago !== null}>
        <DialogTitle>{t('payments.paymentsDetails')}</DialogTitle>
        <DialogContent>
          {selectedPago && (
            <Box>
              <Typography variant="body1">
                <strong>{t('payments.name')}:</strong> {selectedPago.name}
              </Typography>
              <Typography variant="body1">
                <strong>{t('payments.type')}:</strong> {selectedPago.recurring ? t('payments.recurring') : t('payments.unique')}
              </Typography>
              <Typography variant="body1">
                <strong>{t('payments.amount')}:</strong> {selectedPago.amount ? selectedPago.amount : '-'}
              </Typography>
            </Box>
          )}
          <Button onClick={handleCloseDialog} style={{ marginTop: '16px' }} variant="contained">
            {t('payments.close')}
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth="md" onClose={handleCloseCreateModal} open={openCreateModal}>
        <DialogTitle>{t('payments.newPayment')}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'grid', gap: 3, gridTemplateColumns: '1fr 1fr' }}>
            <Box>
              <TextField
                error={formik.touched.name && Boolean(formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label={t('payments.name')}
                margin="normal"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t('payments.exampleMantainance')}
                required
                value={formik.values.name}
                variant="outlined"
              />

              <FormControlLabel
                control={<Checkbox checked={formik.values.recurring} name="recurring" onChange={formik.handleChange} />}
                label={t('payments.paymentRecurrente')}
                sx={{ marginBottom: '16px' }}
              />

              <TextField
                error={formik.touched.recurringChargePeriod && Boolean(formik.errors.recurringChargePeriod)}
                fullWidth
                helperText={formik.touched.recurringChargePeriod && formik.errors.recurringChargePeriod}
                label={t('payments.periodicity')}
                margin="normal"
                name="recurringChargePeriod"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                select
                value={formik.values.recurringChargePeriod}
                variant="outlined"
              >
                <MenuItem value="month">{t('payments.month')}</MenuItem>
                <MenuItem value="year">{t('payments.year')}</MenuItem>
              </TextField>
            </Box>

            <Box>
              <TextField
                disabled={formik.values.variableCost}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                fullWidth
                helperText={formik.touched.amount && formik.errors.amount}
                label={t('payments.amount')}
                margin="normal"
                name="amount"
                onBlur={e => {
                  formik.handleBlur(e);
                  if (!formik.values.variableCost) {
                    formik.validateField('amount');
                  }
                }}
                onChange={formik.handleChange}
                placeholder="$2500"
                required={!formik.values.variableCost}
                value={formik.values.amount}
                variant="outlined"
              />

              <FormControlLabel
                control={<Checkbox checked={formik.values.variableCost} name="variableCost" onChange={handleVariableCostChange} />}
                label={t('payments.costVariable')}
                sx={{ marginBottom: '16px' }}
              />

              <FormControl error={formik.touched.category && Boolean(formik.errors.category)} fullWidth margin="normal" variant="outlined">
                <InputLabel>{t('payments.category')}</InputLabel>
                <Select
                  label={t('payments.category')}
                  name="category"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.category}
                >
                  <MenuItem value="P">{t('payments.product')}</MenuItem>
                  <MenuItem value="S">{t('payments.service')}</MenuItem>
                  <MenuItem value="R">{t('payments.booking')}</MenuItem>
                </Select>
                {formik.touched.category && formik.errors.category && (
                  <Typography color="error" variant="caption">
                    {formik.errors.category}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" marginTop={3}>
            <Button onClick={handleCloseCreateModal} sx={{ marginRight: 2 }} variant="outlined">
              {t('payments.close')}
            </Button>
            <Button
              color="primary"
              disabled={!isFormReady}
              onClick={formik.handleSubmit}
              sx={{
                backgroundColor: !isFormReady ? 'grey.400' : 'primary.main',
                cursor: !isFormReady ? 'not-allowed' : 'pointer'
              }}
              variant="contained"
            >
              {isEditing ? t('payments.update') : t('payments.save')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ComponentePaymentsRules;
